<template>
  <div id="app">
    <div class="section">
      <div class="columns">
        <div class="column is-12">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="css">
.pre-formatted {
  white-space: pre-wrap;
}
</style>
