<template>
  <div>
    <form @submit.prevent="login" />
    <div class="columns is-centered">
      <div class="column is-half has-text-centered has-text-grey is-size-4">
        <img
          src="@/assets/logo.png"
          alt="Logo Woken"
          style="max-height: 100px"
        >
        <br>Registro de Entrega de Elementos de Protección Personal
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-half">
        <b-field label="Usuario">
          <b-input v-model="username" />
        </b-field>
        <b-field label="Contraseña">
          <b-input
            v-model="password"
            type="password"
          />
        </b-field>
        <b-button
          type="is-primary"
          size="is-medium"
          :disabled="filled"
          @click="doLogin"
        >
          Ingresar
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Button, Field, Input } from 'buefy'

Vue.use(Button)
Vue.use(Field)
Vue.use(Input)

export default {
  name: 'LoginView',
  data: function () {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    filled: function () {
      return !(this.username.length && this.password.length)
    }
  },
  methods: {
    doLogin: async function () {
      const username = this.username
      const password = this.password
      await this.$store.dispatch('auth/login_user', { username, password })
      if (this.$store.getters['auth/isLogged']) {
        this.$router.push('/formulario')
      }
    },
    linkWebSite () {
      window.location = 'https://woken.cl/'
    },
    linkWebContact () {
      window.location = 'https://woken.cl/contacto/'
    }
  }
}
</script>
