import Vue from 'vue'

const form = {
  namespaced: true,
  state: {
    answers: {},
    images: {}
  },
  mutations: {
    saveAnswer (state, { item, value, tagResponse = null, tag: fieldTag }) {
      const tag = tagResponse !== null ? tagResponse : 0
      if (state.answers[tag] === undefined) {
        Vue.set(state.answers, tag, {})
      }
      Vue.set(state.answers[tag], item, { item, id: item, value, tagResponse, tag: fieldTag })
    },
    saveImage (state, { item, images, tagResponse }) {
      const tag = tagResponse !== null ? tagResponse : 0
      if (state.images[tag] === undefined) {
        Vue.set(state.images, tag, {})
      }
      Vue.set(state.images[tag], item, { item, images, tagResponse })
    },
    clearAnswers (state) {
      const project = (state.answers[0] || {})._project || {}
      state.answers = { 0: { _project: project } }
      state.images = {}
    }
  },
  actions: {
    async setAnswer ({ commit }, item) {
      commit('saveAnswer', item)
    },
    setImages ({ commit }, { item, images, tagResponse }) {
      commit('saveImage', { item, images, tagResponse })
    },
    clearAnswers ({ commit }) {
      commit('clearAnswers')
    }
  },
  getters: {
    allAnswers: (state) => {
      const answers = []
      for (const subitems of Object.values(state.answers)) {
        Object.values(subitems).forEach(e => answers.push(e))
      }
      return answers
    },
    getAnswer: (state, getters) => (fid, tagResponse = null) => {
      const answers = getters.allAnswers
      return Object.values(answers).find(e => e.item == fid && tagResponse == e.tagResponse)
    },
    getCollectorByDosis: (state, getters) => (dosis) => {
      const answers = getters.allAnswers
      return Object.values(answers).find(e => e.value == dosis)?.tagResponse
    },
    allImages: (state) => {
      const answers = []
      for (const subitems of Object.values(state.images)) {
        Object.values(subitems).forEach(e => answers.push(e))
      }
      return answers
    },
    hasAnswer: (state, getters) => (fid, tagResponse = null) => {
      const answers = getters.allAnswers
      return Object.values(answers).find(e => e.item == fid && tagResponse == e.tagResponse)?.value || false
    }
  }
}

export default form
